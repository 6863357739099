.confirmation-modal {
    height:80%;
    display: flex;
    justify-content: center;
    text-align: center; 
    align-items:center;
    }
  
    .confirmation-modal-header {
      background: rgb(18, 207, 81)
    }