.center-container {
    position: relative;
    height: 100%;
  }
  
.center-content {
    width: 80%;
    height: 80%;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

  }

  .content {
    text-align: center;
  }

  .continue-button{
   margin-left: 80%;

  }