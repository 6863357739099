.button-custom {
  width: 100%;
}

.conversations-custom-control {
  max-width: 60%;
}

.queues-multi-select .dropdown-content,
.clients-multi-select .dropdown-content {
  min-width: 540px;
}


.queues-multi-select .dropdown-content .item-renderer span,
.clients-multi-select .dropdown-content .item-renderer span {
  display: inline-block;
  word-break: break-all;
}

.btn-space {
  margin-right: 5px;
}