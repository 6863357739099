.waiting-modal--centered {
    height:80%;
    display: flex;
    justify-content: center;
    text-align: center; 
    align-items:center;
}

.waiting-modal__spinner {
  color: #f15833;
}
