.alert-modal {
  height:80%;
  display: flex;
  justify-content: center;
  text-align: center; 
  align-items:center;
  }

  .alert-modal-header {
    background: rgba(255, 99, 71, 1)
  }