.details-header{
    font-weight: bold;
}

.conversations-details-header-text{
    font-weight: bold;
    font-size:larger;
}

.conversation-details-header-bar{
    justify-content: center;
    align-items: center;
}