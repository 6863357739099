.details-header{
    font-weight: bold;
}

.container-email-body {
    word-wrap:break-word;
    /* max-width: 100px; */
}

.container-email-transcript{
    max-height: 400px;
    border:1px solid black;
    width: calc(100% - 15px)
}

.email-container {
  flex: 1 1 auto;
  display: flex;
  margin: 5px;
  overflow-y: auto;
  max-width: 100%;
}

.btn {
    box-shadow: none !important;
    outline: 0;
  }
  
  .list-group-item .custom-arrow {
    border: solid #222;
    border-width: 0 1px 1px 0;
    display: inline;
    cursor: pointer;
    padding: 3px;
    position: absolute;
    right: 0;
    margin-top: 10px;
  }
  
  .list-group-item a.btn.collapsed .custom-arrow {
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    transition: .3s transform ease-in-out;
  }
  
  
  .list-group-item a.btn .custom-arrow {
    transform: rotate(-140deg);
    -webkit-transform: rotate(-140deg);
    transition: .3s transform ease-in-out;
  }

  .list-group-item {
    border: solid gray;
  }

  .custom-padding{
    padding: .375rem .75rem;
  }

  .email-date-header{
    color:rgba(149, 165, 166, 1);
    margin-left:15px;
  }