.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-toggler {
    border: 1px solid rgba(255, 255, 255);
  }

  .header {
      background-color: #393E45;
  }
  
  .navbar-brand {
      color:#FDFDFD !important;
  }

  .nav-link {
     color:#FDFDFD !important;
  }

 .dropdown-menu {
    background-color:#393E45;
  }

  .dropdown-item:hover {
    background-color: gray;
  }

.nav-link:hover{
  font-weight: 600;

}
