.list-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    flex: 1;
    height: 35%;


    }

.title-name{
    text-align: center;
    margin-left: 2%;
    font-size: 135%;
}

.label-name{
    margin-top: 7%;
    margin-left:  10%;
    margin-right:  10%;
}


.buttons{
    margin-left: 10%;
    align-items: center;
}

.hori-line{
        color: #000000;
        margin-left: 1%;
        margin-right: 1%;
}
/* 
.container {
    height:806px;
} */

