.footer {
  min-height: 56px;
  clear: both;
  padding-top: 16px;
  text-align: right;
  background-color: #393e45;
  color: white;
}

span {
  margin-right: 10px;
}