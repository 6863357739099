* {
  box-sizing: border-box
} 

html, body {
  height: 100%;
}

.multi-select {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 60%;
}

#wrap {
   min-height: 100%;
}

#main {
  overflow-x:hidden;
  overflow-y:auto;
  height: calc(100vh - 56px);
}

.container {
    margin-top:15px;
    overflow-y:auto;
}

.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
}
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px;
}
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
}

@media only screen and (min-width : 1200px) {
  .container { max-width: none; }
}

.headerColumn{
  font-weight: 500;
  /* background-color:rgba(0,0,0,0.1); */
  background-color: lightgrey;
}

button {
  margin-bottom:5px;
  margin-right:5px;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 15px;
  background-color:transparent;
}

button:active:enabled {
  transform: translateY(4px);
}

.div-center {
  text-align:center
}

label {
    font-weight: 500;
}

.call-modal > span {
  position: relative;
  display: block;
}

.margin-top{
  margin-top:20px;
}

.menu {
  background-color: #fff;
  border-radius: 2px;
  padding-left: 0;
  margin: 0;
  position: absolute;
  list-style: none;
}

.menu li {
  padding: 0.2em 1em;
  color: #000;
  cursor: pointer;
}

.menu li:hover {
  background-color: #f2f2f2;
}

.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  overflow: hidden;
  background: #fff;
}

.input-group-text{
  width: 150px;
  justify-content: center;
  border-radius:5px !important
}

.form-control{
  border-radius:5px !important
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 5px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

.table-container {
  flex: 1 1 auto;
  display: flex;
  margin: 5px;
  overflow-y: auto;
}

.date-picker-error {
  border: 1px solid red;
  border-radius:5px;
}

.button-custom {
  width:100%;
}

.overflow {
  overflow:auto;
}

.headerColumn{
  font-weight: 500;
  /* background-color:rgba(0,0,0,0.1); */
  background-color: lightgrey;
}

.search-wrap {
  width: 90%;
}

#component-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
}

#component-toolbar {
  height: 46px;
  padding: 5px;
}