.details-header{
    font-weight: bold;
}

textarea {
    resize: none;
    width: 100%;
    height: 400px;
    word-wrap: break-word;
}

.container-chat-transcript{
    width: calc(100% - 15px)
}