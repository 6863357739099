.required-astrik {
    color: red;
}

/* .form-elements{
    width: 70%;
    height : 80%;
} */

.single-select{
    width: 540px;
}

.multi-drop-down {
   width: 540px;
  }


.text-box{
    width : 700px;
}

.form-elements {
        margin-left: 30%;
  }
  